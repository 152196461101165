@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
  font-family: 'Lato', sans-serif;
  background-color: #fafcff;
  overflow-x: hidden;
}

input:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #ced4da !important;
}

select:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.input:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.wrapper {
  height: 100vh;
}

.h-100 {
  height: 100vh !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.bg-blue {
  background-color: #65b7ff !important;
}

.border-left-presentase {
  border-left: 0.25rem solid #0e8388 !important;
  border-top: #fafcff !important;
  border-right: #fafcff !important;
  border-bottom: #fafcff !important;
  color: #0e8388;
}

.border-left-responden {
  border-left: 0.25rem solid #3f497f !important;
  border-top: #fafcff !important;
  border-right: #fafcff !important;
  border-bottom: #fafcff !important;
  color: #3f497f;
}

.border-left-responden:hover {
  background-color: #93bfcf;
}

.border-left-selesai {
  border-left: 0.25rem solid #468966 !important;
  border-top: #fafcff !important;
  border-right: #fafcff !important;
  border-bottom: #fafcff !important;
  color: #468966;
}

.border-left-selesai:hover {
  background-color: #b2e672;
}

.border-left-proses {
  border-left: 0.25rem solid #f1c93b !important;
  border-top: #fafcff !important;
  border-right: #fafcff !important;
  border-bottom: #fafcff !important;
  color: #f1c93b;
}

.border-left-proses:hover {
  background-color: #fffd88;
}

.border-left-mulai {
  border-left: 0.25rem solid #c13131 !important;
  border-top: #fafcff !important;
  border-right: #fafcff !important;
  border-bottom: #fafcff !important;
  color: #c13131;
}

.border-left-mulai:hover {
  background-color: #ff8c8c;
}

.text-xs {
  font-size: 0.7rem;
}

.text-center-ul {
  display: inline-block;
  text-align: left;
}

.text-sidebar {
  font-size: 14px;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.sidebar-menu {
  padding: 13px;
}

.sidebar-menu:hover {
  background-color: #4f709c;
  border-radius: 10px;
}

.text-blue {
  color: #65b7ff !important;
}

.text-gray-300 {
  color: #dddfeb !important;
}

.text-justify {
  text-align: justify;
}

.no-bullets {
  list-style-type: none;
}

.fa-2x {
  font-size: 2em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.pie-chart {
  width: 10vh;
  height: 10vh;
}

.pointer {
  cursor: pointer;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-70 {
  width: 70% !important;
}

.bg-pink {
  background-color: rgb(245, 199, 199) !important;
}

.bg-yellow {
  background-color: rgb(255, 226, 174) !important;
}

.round-div {
  width: 25% !important;
}

.round-div2 {
  width: 33px;
  height: 33px;
  background-color: #4a55a2;
}

.lh-xl {
  line-height: 2.3;
}

.radar-chart {
  width: 80vh !important;
  height: 80vh !important;
}

.dropdown-toggle::after {
  border: none !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: white !important;
}

.w-20 {
  width: 20% !important;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: var(--bs-table-striped-bg);
  --bs-table-accent-bg: none;
}

.mapping-wrapper {
  width: 650px;
  border-left: 5px solid #4472c4;
  border-bottom: 5px solid #4472c4;
  padding: 5px;
  position: relative;
}

.box-left {
  position: absolute;
  padding: 20px;
  left: -100px;
  border-radius: 10px;
  color: white;
  height: 70px;
}

.box-bottom {
  position: absolute;
  padding: 10px 20px;
  bottom: -90px;
  border-radius: 10px;
}

.h-65vh {
  height: 65vh;
}

.mapping-grid {
  width: 98%;
  height: 98%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.mapping-grid p {
  margin: 0;
}

/* quiz test */
.quiz-container {
  /* max-width: 500px; */
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 60px;
}

.quiz-container .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #4a55a2;
}

.quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}

.quiz-container ul .selected-answer {
  background: #a0bfe0;
  border: 1px solid #4a55a2;
}

.quiz-container button {
  background: linear-gradient(90.04deg, #4a55a2 0.03%, #a0bfe0 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.mb-number {
  margin-bottom: 24.4px;
}

.input-number {
  width: 35px !important;
  height: 32px !important;
  margin-bottom: 16.5px;
}

.input-number:focus, textarea:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.border-blue {
  border-style: solid !important;
  border-color: #132396 !important;
  border-width: 2px !important;
}

.line-horizontal {
  height: 2px;
  margin-left: 11%;
  margin-bottom: 15px;
  position: relative;
  background: #353ebe;
}

.arrow-left:after,
.arrow-right:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 5px solid transparent;
}

.arrow-left:after,
.arrow-right:before {
  top: -4px;
}
.arrow-left:after {
  left: -15px;
  border-right: 15px solid #353ebe;
}
.arrow-right:before {
  right: -15px;
  border-left: 15px solid #353ebe;
}

.rotated-left {
  /* writing-mode: tb-rl; */
  /* transform: rotate(-180deg); */
  margin-right: 1.5%;
  margin-top: 10%;
}

.rotated-left2 {
  /* writing-mode: tb-rl;
  transform: rotate(-180deg); */
  margin-left: 1.5%;
  margin-top: 7%;
  position: absolute;
}

.rotated-right {
  /* writing-mode: tb-rl;
  transform: rotate(180deg); */
  margin-left: 1.5%;
  margin-top: 10%;
}